import AddPlaylist from '~/assets/icons/add_playlist.svg'
import AppleIcon from '~/assets/icons/apple.svg'
import CommentIcon from '~/assets/icons/comment.svg'
import FacebookIcon from '~/assets/icons/facebook.svg'
import FilmarksIcon from '~/assets/icons/filmarks.svg'
import FlagIcon from '~/assets/icons/flag.svg'
import GoogleIcon from '~/assets/icons/google.svg'
import HomeIcon from '~/assets/icons/home.svg'
import InfoIcon from '~/assets/icons/info.svg'
import LikeIcon from '~/assets/icons/like.svg'
import ListNone from '~/assets/icons/list_none.svg'
import MyListIcon from '~/assets/icons/my_list.svg'
import PenIcon from '~/assets/icons/pen.svg'
import PlayIcon from '~/assets/icons/play.svg'
import PlayList from '~/assets/icons/playlist.svg'
import SearchIcon from '~/assets/icons/search.svg'
import SettingIcon from '~/assets/icons/setting.svg'
import ShareIcon from '~/assets/icons/share.svg'
import TimesCircleIcon from '~/assets/icons/times_circle.svg'
import TornTicket from '~/assets/icons/torn_ticket.svg'
import TrashBox from '~/assets/icons/trash_box.svg'
import TwitterIcon from '~/assets/icons/twitter.svg'
import UserIcon from '~/assets/icons/user.svg'
import XIcon from '~/assets/icons/x.svg'
import LogoIcon from '~/assets/logo.svg'
import LogoWithText from '~/assets/logo_with_text.svg'
import { SvgAssetType } from './type'

export const getComponent = (asset: SvgAssetType) => {
  switch (asset) {
    case 'apple':
      return AppleIcon
    case 'comment':
      return CommentIcon
    case 'facebook':
      return FacebookIcon
    case 'flag':
      return FlagIcon
    case 'google':
      return GoogleIcon
    case 'home':
      return HomeIcon
    case 'like':
      return LikeIcon
    case 'my_list':
      return MyListIcon
    case 'setting':
      return SettingIcon
    case 'search':
      return SearchIcon
    case 'share':
      return ShareIcon
    case 'twitter':
      return TwitterIcon
    case 'x':
      return XIcon
    case 'user':
      return UserIcon
    case 'logo':
      return LogoIcon
    case 'logo_with_text':
      return LogoWithText
    case 'list_none':
      return ListNone
    case 'torn_ticket':
      return TornTicket
    case 'play':
      return PlayIcon
    case 'trash_box':
      return TrashBox
    case 'pen':
      return PenIcon
    case 'times_circle':
      return TimesCircleIcon
    case 'add_playlist':
      return AddPlaylist
    case 'playlist':
      return PlayList
    case 'info':
      return InfoIcon
    case 'filmarks':
      return FilmarksIcon
  }
}

export const getViewBox = (asset: SvgAssetType) => {
  switch (asset) {
    case 'apple':
    case 'facebook':
    case 'google':
    case 'twitter':
      return '0 0 40 41'
    case 'logo':
      return '0 0 29 30'
    case 'logo_with_text':
      return '0 0 114 30'
    case 'list_none':
      return '0 0 120 76'
    case 'torn_ticket':
      return '0 0 271 173'
    case 'play':
      return '0 0 9 10'
    case 'trash_box':
      return '0 0 21 24'
    case 'pen':
      return '0 0 23 23'
    case 'times_circle':
      return '0 0 33 33'
    case 'add_playlist':
      return '0 0 19 24'
    case 'info':
      return '0 0 19 19'
    case 'x':
      return '0 0 1200 1227'
    case 'filmarks':
      return '0 0 27 28'
    default:
      return '0 0 32 32'
  }
}
