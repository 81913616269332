import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

const BannerTimestampKey = 'bannerTimestamp';
const OneWeekInMs = 7 * 24 * 60 * 60 * 1000;

export const useAppBanner = () => {
  const [showedBanner, setShowedBanner] = useState<boolean>(false);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem(BannerTimestampKey);
    if (isMobile) {
      if (storedTimestamp === null) {
        setShowedBanner(true);
      } else {
        const timestamp = Number(storedTimestamp);
        const currentTime = Date.now();
        if (currentTime >= timestamp + OneWeekInMs) {
          localStorage.removeItem(BannerTimestampKey);
          setShowedBanner(true);
        }
      }
    }
  }, []);

  const closeBanner = useCallback(() => {
    localStorage.setItem(BannerTimestampKey, Date.now().toString());
    setShowedBanner(false);
  }, []);

  return { showedBanner, closeBanner };
};
