import styled from '@emotion/styled'
import { Box, CircularProgress, Modal } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { useVideoPreviewModalActions } from '~/features/video/hooks/useVideoPreviewModalActions'
import { useVideoPreviewModalInfo } from '~/features/video/hooks/useVideoPreviewModalInfo'
import { Colors } from '~/libs/mui/theme'
import { previewVideoState } from '~/recoil/preview'
import { VideoPreviewModalContent } from './VideoPreviewModalContent'

const ModalFrame = styled(Box)(() => ({
  position: 'relative',
  width: '90%',
  maxWidth: '600px',
  borderRadius: '12px',
  padding: 16,
  backgroundColor: Colors.color1A1A18,
}))

export function VideoPreviewModal(): JSX.Element {
  const previewVideoId = useRecoilValue(previewVideoState)
  const videoPreviewModalInfo = useVideoPreviewModalInfo()
  const { onClose, ...videoPreviewModalActions } = useVideoPreviewModalActions()

  return (
    <Modal
      open={previewVideoId !== undefined}
      onClose={onClose}
      aria-labelledby='video-preview-title'
      aria-describedby='video-preview-description'
      /**
       * NOTE: MUIはz-indexの設定が modal(1300) > drawer(1200)になっている。
       * プレイリストアクションUIがmodalとdrawerの2つが同時に表示されるケースがあるため、それらよりも下にプレビューモーダルが表示されるように1199を指定
       */
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1199 }}
    >
      <ModalFrame>
        {videoPreviewModalInfo === 'loading' ? (
          <Box display='flex' justifyContent='center' padding={4}>
            <CircularProgress color='primary' />
          </Box>
        ) : (
          <VideoPreviewModalContent
            videoPreviewModalInfo={videoPreviewModalInfo}
            videoPreviewModalActions={videoPreviewModalActions}
          />
        )}
      </ModalFrame>
    </Modal>
  )
}
