'use client'

import { Auth } from 'aws-amplify'
import axios, { isAxiosError } from 'axios'
import { Session } from 'next-auth'
import { signOut, useSession } from 'next-auth/react'
import { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { accessTokenState } from '~/recoil/auth'
import browserEnv from '../env/browserEnv'

const createApi = (
  accessToken: string | undefined,
  onRefreshToken: (newToken: string) => void | undefined,
  snsSession: Session | null,
) => {
  const api = axios.create({
    baseURL: browserEnv.NEXT_PUBLIC_API_HOST,
    timeout: 60000,
  })

  api.interceptors.request.use(async (config) => {
    const headers = config.headers ?? {}

    if (browserEnv.NEXT_PUBLIC_ENV === 'production') {
      headers['Authorization'] =
        headers['Authorization'] ??
        (accessToken === undefined ? undefined : `Bearer ${accessToken}`)
    } else {
      let token: string | undefined
      if (snsSession) {
        token = snsSession.access_token
      } else {
        try {
          const session = await Auth.currentSession()
          token = session.getAccessToken().getJwtToken()
        } catch (e) {}
      }

      headers['Authorization'] =
        headers['Authorization'] ?? (token === undefined ? undefined : `Bearer ${token}`)
    }

    config.headers = headers
    return config
  })
  api.interceptors.response.use(
    (res) => {
      return res
    },
    async (error) => {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case 401:
            const originalRequest = error.config
            if (originalRequest && originalRequest.retry != true) {
              // refresh token and retry once
              try {
                const session = await Auth.currentSession()
                const newToken = session.getAccessToken().getJwtToken()
                const headers = originalRequest.headers ?? {}
                headers['Authorization'] = `Bearer ${newToken}`
                originalRequest.headers = headers
                originalRequest.retry = true
                onRefreshToken?.(newToken)
                return axios(originalRequest)
              } catch (error) {
                console.error(error)
              }
            }
            signOut({ callbackUrl: '/login' })
            return { data: null }
          case 503:
            window.location.href = '/maintenance'
            break
          default:
            break
        }
      }
      switch (error.code) {
        case 'ECONNABORTED':
          const errorPath = window.location.pathname
          window.location.href = `/timeout?error-path=${errorPath}`
          break
      }
      console.error(error)
      return Promise.reject(error)
    },
  )

  return api
}

export const useAxios = () => {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
  const { data: snsSession } = useSession()

  return useMemo(
    () => createApi(accessToken, setAccessToken, snsSession),
    [accessToken, setAccessToken],
  )
}
