'use client'

import { useQuery } from '@apollo/client'
import { Box, Paper, Snackbar, useMediaQuery } from '@mui/material'
import { DeviceContext, getDevice } from 'app/_hooks'
import { Auth } from 'aws-amplify'
import { isAxiosError } from 'axios'
import { FC, ReactNode, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { GetCurrentUserDocument, GetCurrentUserQuery } from '~/__generated__/graphql'
import { AppBottomNavigation } from '~/components/AppBottomNavigation'
import { AppFooter } from '~/components/AppFooter'
import { useInitialAccessToken } from '~/features/auth/hooks/useInitialAccessToken'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { PlayListVideoActionController } from '~/features/play_list/components/PlayListVideoActionController'
import { SettingPreparation } from '~/features/setting/components/SettingPreparation'
import { VideoPreviewModal } from '~/features/video/components/VideoPreviewModal'
import ApolloProviderWrapper from '~/libs/apollo'
import { useAxios } from '~/libs/axios'
import * as GA from '~/libs/ga'
import theme from '~/libs/mui/theme'
import { accessTokenState, loginInfoState, userLoginState } from '~/recoil/auth'
import { snackbarTextState } from '~/recoil/snackbar'
import { Profile, profileIsDuringFreeTrial } from '~/types/api/users'

type AuthLayoutProps = {
  children?: ReactNode
}

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <SettingPreparation>
      <ApolloProviderWrapper>
        <DeviceContext.Provider
          value={typeof navigator === 'undefined' ? 'PC' : getDevice(navigator.userAgent)}
        >
          {/** TODO: 一旦、AuthLayoutにしているが未ログイン対応が入るので将来的には別のレイアウトに吐き出される */}
          <_AuthLayout>{children}</_AuthLayout>
        </DeviceContext.Provider>
      </ApolloProviderWrapper>
    </SettingPreparation>
  )
}

const _AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const axios = useAxios()
  const localeParams = useAddLocaleParams()
  const accessToken = useRecoilValue(accessTokenState)
  const initialAccessTokenWorking = useInitialAccessToken()
  const setUserLoginState = useSetRecoilState(userLoginState)
  const setLoginInfoState = useSetRecoilState(loginInfoState)

  const { data, error } = useQuery<GetCurrentUserQuery>(GetCurrentUserDocument)
  const {
    data: profile,
    error: profileError,
    isLoading,
  } = useSWR<Profile>(
    initialAccessTokenWorking === 'done' && accessToken !== undefined
      ? `/v2/user/edit?${localeParams}`
      : null,
    (args) => axios.get(args).then((res) => res.data),
  )

  // トークンのセットが完了したらユーザーのログイン状態を更新する
  useEffect(() => {
    if (initialAccessTokenWorking === 'inprogress') return
    if (isLoading) return

    // uBlockなどの広告ブロッカーが有効な場合、DD_RUMが読み込まれないため、チェックしてから使用する
    if (window.DD_RUM && typeof window.DD_RUM.setUser === 'function') {
      Auth.currentUserInfo().then((user) => {
        window.DD_RUM.setUser({
          cognitoUsername: user?.username,
          cognitoEmail: user?.attributes?.email,
        })
      })
    }

    if (profileError && isAxiosError(profileError) && profileError.response?.status === 406) {
      setUserLoginState('preventNewSocialSignedIn')
      return
    }

    if (error) {
      const graphQLError = error.graphQLErrors[0]
      const reason = graphQLError.extensions.reason
      switch (reason) {
        case 'REQUIRE_PROFILE':
          setUserLoginState('noProfile')
          return
        case 'PROVIDER_MISMATCH':
          setUserLoginState('conflict')
          setLoginInfoState({
            email: graphQLError.extensions.email as string,
            provider: graphQLError.extensions.provider as string,
          })
          return
        default:
          break
      }
      setUserLoginState('notSignedIn')
      GA.setUserType('notLogin')
    } else if (data?.currentUser != undefined) {
      setUserLoginState('signedIn')
      if (window.DD_RUM && profile && typeof window.DD_RUM.setUser === 'function') {
        window.DD_RUM.setUser({
          id: (profile.id ?? '').toString(),
          name: profile.name,
          email: profile.email,
        })
      }
    } else if (accessToken == undefined) {
      setUserLoginState('notSignedIn')
      GA.setUserType('notLogin')
    }
  }, [
    initialAccessTokenWorking,
    data,
    setUserLoginState,
    accessToken,
    setLoginInfoState,
    error,
    profile,
    profileError,
  ])

  useEffect(() => {
    if (initialAccessTokenWorking === 'inprogress') return
    if (profile != undefined) {
      switch (profile.user_status) {
        case 'free_user':
          GA.setUserType('free')
          break
        case 'subscription_user':
          GA.setUserType(profileIsDuringFreeTrial(profile) ? 'trial' : 'paying')
          break
        case 'withdrawal_user':
          GA.setUserType(
            profileIsDuringFreeTrial(profile)
              ? 'trialCancel'
              : profile.payment_type != 'no_subscription'
              ? 'payingCancel'
              : 'paying',
          )
          break
        default:
          break
      }
      GA.setUserId(profile.id)
      return
    }
  }, [initialAccessTokenWorking, profile])

  return (
    <Box>
      {children}

      <Footer />

      {/* others */}
      <VideoPreviewModal />
      <PlayListVideoActionController />
      <AppSnackbar />
    </Box>
  )
}

const Footer: FC = () => {
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  return isSm ? (
    <Box bottom={0} left={0} right={0}>
      <AppFooter />
    </Box>
  ) : (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <AppBottomNavigation />
    </Paper>
  )
}

const AppSnackbar: FC = () => {
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextState)

  return (
    <Snackbar
      open={snackbarText != undefined}
      autoHideDuration={3000}
      onClose={() => {
        setSnackbarText(undefined)
      }}
      message={snackbarText}
    />
  )
}

export default AuthLayout
