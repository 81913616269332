import { useCallback, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

export const useCueFontSizeOptimizer = () => {
  const getActiveDisplayWidth = useCallback(() => {
    const video = document.querySelector('video')

    if (!video) return 0

    const videoAspectRatio = video.videoWidth / video.videoHeight // 元動画のアスペクト比
    const displayAspectRatio = video.clientWidth / video.clientHeight // 表示領域のアスペクト比

    const devicePixelRatio = window.devicePixelRatio || 1

    return videoAspectRatio > displayAspectRatio
      ? video.clientWidth * devicePixelRatio
      : video.clientHeight * devicePixelRatio * videoAspectRatio
  }, [])

  const updateCueFontSize = useCallback(() => {
    const activeDisplayWidth = getActiveDisplayWidth()

    if (activeDisplayWidth === 0) return

    const fontSize = Math.floor(activeDisplayWidth / 40)

    let styleElement = document.getElementById('global-cue-style')
    if (!styleElement) {
      styleElement = document.createElement('style')
      styleElement.id = 'global-cue-style'
      document.head.appendChild(styleElement)
    }

    styleElement.innerHTML = `
        video::cue {
          font-size: ${fontSize}px;
        }
      `
  }, [getActiveDisplayWidth])

  useEffect(() => {
    updateCueFontSize()

    const handleResize = () => {
      !isMobile && updateCueFontSize() // 画面回転時にもresizeイベントが発火するため、isMobileの場合は無視
    }
    window.addEventListener('resize', handleResize)
    document.addEventListener('fullscreenchange', handleResize)

    const observer = new MutationObserver(() => {
      const video = document.querySelector('video')
      if (video) {
        video.addEventListener('play', handleResize)
      }
    })
    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('fullscreenchange', handleResize)
      observer.disconnect()
    }
  }, [updateCueFontSize])
}
