'use client'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Box, Button, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { Trans } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { URLConstants } from '~/libs/constants'
import { useAppBanner } from './hooks'

export const Banner: React.FC = () => {
  const { showedBanner, closeBanner } = useAppBanner()
  if (!showedBanner) return null
  return (
    <>
      <AppBar sx={{ zIndex: 1200 }}>
        <Stack
          direction='row'
          spacing={0}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <Stack direction='row'>
            <IconButton
              color='secondary'
              onClick={() => {
                closeBanner()
              }}
            >
              <CloseIcon />
            </IconButton>
            <Stack direction='row'>
              <Box
                sx={{
                  margin: '4px',
                  width: '50px',
                  height: '50px',
                  backgroundColor: 'black',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <SvgAsset asset='logo' />
              </Box>
              <Stack
                direction='column'
                spacing={0.5}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'stretch',
                }}
              >
                <Typography>SAMANSA</Typography>
                <Typography variant='caption'>
                  <Trans i18nKey='download_the_app_now' />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Button variant='text' color='primary' href={URLConstants.appStorePage}>
            <Typography variant='body1'>
              <Trans i18nKey='open' />
            </Typography>
          </Button>
        </Stack>
      </AppBar>
    </>
  )
}
