'use client'

import { CacheProvider, ThemeProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { Banner } from 'app/_components/Banner'
import { useCueFontSizeOptimizer } from 'app/_hooks'
import { Amplify } from 'aws-amplify'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import NextTopLoader from 'nextjs-toploader'
import { FC, PropsWithChildren } from 'react'
// @ts-ignore
import { RecoilRoot } from 'recoil'
import { SWRConfig } from 'swr'
import { awsExports } from '~/aws'
import { AppLanguagePreparation } from '~/features/i18n/components/AppLanguagePreparation'
import createEmotionCache from '~/libs/mui/createEmotionCache'
import theme, { Colors } from '~/libs/mui/theme'

Amplify.configure({ ...awsExports, ssr: true })

export const Base: FC<PropsWithChildren<{ session: Session | null }>> = ({ session, children }) => {
  // You can consider sharing the same Emotion cache between all the SSR requests to speed up performance.
  // However, be aware that it can have global side effects.
  const cache = createEmotionCache()
  useCueFontSizeOptimizer()

  return (
    <RecoilRoot>
      <SessionProvider session={session}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <SWRConfig
              value={{
                revalidateOnFocus: false,
              }}
            >
              <CssBaseline />
              <NextTopLoader color={Colors.yellow} showSpinner={false} />
              <AppLanguagePreparation>
                <Banner />
                {children}
              </AppLanguagePreparation>
            </SWRConfig>
          </ThemeProvider>
        </CacheProvider>
      </SessionProvider>
    </RecoilRoot>
  )
}
