'use client'

import { Box, Button, Stack, Typography } from '@mui/material'
import { NextPage } from 'next'
import { useRouter } from 'next/navigation'
import { Trans } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { Colors } from '~/libs/mui/theme'

const ErrorPage: NextPage<{}> = ({}) => {
  const { isSm } = useMediaQuery()
  const { push } = useRouter()

  return (
    <Box
      height='80vh'
      display='flex'
      flexDirection='column'
      alignItems='center'
      pt={3}
      px={4}
      pb={15}
    >
      <Box display='flex' gap={1} alignItems='center' justifyContent='center'>
        {' '}
        <LogoWithTextIcon height={isSm ? 48 : 32} />
      </Box>
      <Box display='flex' gap={3} flexGrow={1} flexDirection='column' justifyContent='center'>
        <Typography
          variant='h1'
          sx={{ color: Colors.colorD7D9DB, fontSize: { xs: 20, sm: 32 }, fontWeight: 600 }}
        >
          <Trans i18nKey='error_something_went_wrong' />
        </Typography>
        <Typography sx={{ color: Colors.colorD9D9D9 }}>
          <Trans i18nKey='error_something_went_wrong_apologize_please_try_later' />
        </Typography>
      </Box>
      <Stack gap={2}>
        <Button
          variant='outlined'
          sx={{ width: 300, borderWidth: 2 }}
          onClick={() => (window.location.href = '/')} // メモリを解放するためにpushを使わない
        >
          <Trans i18nKey='back' />
        </Button>
        <Button
          sx={{ width: 300, backgroundColor: Colors.color373737, color: Colors.color7E7E80 }}
          onClick={() => (window.location.href = 'https://about.samansa.com/contact-8')}
        >
          <Trans i18nKey='contact' />
        </Button>
      </Stack>
    </Box>
  )
}

// TODO: 長方形のSvgなので、widthとheightをviewBoxの比に合わせてせて指定する必要があるが、現状だとSvgAssetにアセットにその機構がない
function LogoWithTextIcon({ height }: { height: number }) {
  const width = (114 * height) / 30
  return <SvgAsset asset='logo_with_text' sx={{ width, height }} />
}

export default ErrorPage
