import throttle from 'lodash.throttle'
import { usePathname, useRouter } from 'next/navigation'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  playListVideoActionUiTypeState,
  targetActionPlayListVideoInfoState,
} from '~/features/play_list/store'
import * as GA from '~/libs/ga'
import { userLoginState } from '~/recoil/auth'
import { previewVideoState } from '~/recoil/preview'

const eventThresholds = [
  { currentTime: 1, action: 'video_views_time_watched_second' },
  { currentTime: 3, action: 'video_views_3s_watched_count' },
  { currentTime: 5, action: 'video_views_5s_watched_count' },
  { currentTime: 10, action: 'video_views_10s_watched_count' },
  { currentTime: 15, action: 'video_views_15s_watched_count' },
  { currentTime: 30, action: 'video_views_30s_watched_count' },
]

export interface VideoPreviewModalActions {
  onClose: () => void
  onSetup: (video: HTMLVideoElement) => void
  onClickVideoPlay: (videoId: number) => void
  onClickAddPlaylist: (videoId: number) => void
  sendTrailInsightsEvent: (action: string, value?: string) => void
}

export function useVideoPreviewModalActions(): VideoPreviewModalActions {
  const { t } = useTranslation()
  const { push } = useRouter()
  const asPath = usePathname()
  const loginState = useRecoilValue(userLoginState)
  const [previewVideoId, setPreviewVideoId] = useRecoilState(previewVideoState)
  const setUiType = useSetRecoilState(playListVideoActionUiTypeState)
  const setPlayListVideoInfo = useSetRecoilState(targetActionPlayListVideoInfoState)
  const timeUpdateEvents = useRef<Set<number>>(new Set())
  const didSendWatchComplete = useRef(false)
  const screen = useMemo(() => {
    if (asPath === '/') {
      return 'stream'
    } else if (asPath?.startsWith('/search/categories')) {
      return 'category_videos'
    } else if (asPath?.startsWith('/search/result')) {
      return 'search_result'
    }
  }, [asPath])

  const sendTrailInsightsEvent = useCallback(
    (action: string, value?: string) => {
      if (screen === undefined) return
      GA.sendEvent({
        event: 'trail_insights',
        screen,
        category: 'trail',
        action,
        label: `${previewVideoId}`,
        value,
      })
    },
    [screen, previewVideoId],
  )

  const onVolumeChange = useCallback(
    () =>
      throttle(() => {
        sendTrailInsightsEvent('video_views_volume_change')
      }, 3000),
    [sendTrailInsightsEvent],
  )

  const onTimeUpdate = useCallback(
    (video: HTMLVideoElement) => {
      eventThresholds.forEach(({ currentTime, action }) => {
        if (video.currentTime >= currentTime && !timeUpdateEvents.current.has(currentTime)) {
          sendTrailInsightsEvent(action)
          timeUpdateEvents.current.add(currentTime)
        }
      })
      if (!didSendWatchComplete.current && video.currentTime / video.duration > 0.9) {
        sendTrailInsightsEvent('video_views_complete_watched_count')
        didSendWatchComplete.current = true
      }
    },
    [sendTrailInsightsEvent],
  )

  const onSetup = useCallback(
    (video: HTMLVideoElement) => {
      video.addEventListener('play', () => sendTrailInsightsEvent('video_views_play_count'))
      video.addEventListener('pause', () => sendTrailInsightsEvent('video_views_stop_count'))
      video.addEventListener('volumechange', onVolumeChange)
      video.addEventListener('enterpictureinpicture', () =>
        sendTrailInsightsEvent('video_views_pip_on'),
      )
      video.addEventListener('leavepictureinpicture', () =>
        sendTrailInsightsEvent('video_views_pip_off'),
      )
      video.addEventListener('ratechange', () => {
        sendTrailInsightsEvent('video_views_speed_value', `${video.playbackRate}`)
      })
      video.addEventListener(
        'timeupdate',
        throttle(() => onTimeUpdate(video), 1000),
      )
      // video.addEventListener('ended', () => {})
    },
    [onVolumeChange, sendTrailInsightsEvent, onTimeUpdate],
  )

  const sendGaInfo = useCallback(
    (action: string, category: string) => {
      if (screen === undefined) return
      GA.sendEvent({
        event: 'click',
        screen,
        category,
        action,
        label: `${previewVideoId}`,
      })
    },
    [screen, previewVideoId],
  )

  const onClose = useCallback(() => {
    setPreviewVideoId(undefined)
    sendGaInfo('traill_close', 'arena')
  }, [sendGaInfo, setPreviewVideoId])

  const onClickVideoPlay = useCallback(
    (videoId: number) => {
      setPreviewVideoId(undefined)
      sendGaInfo('traill_play_button', 'button')
      void push(`/videos/${videoId}/play`)
    },
    [push, sendGaInfo, setPreviewVideoId],
  )

  const onClickAddPlaylist = useCallback(
    (videoId: number) => {
      sendGaInfo('traill_mylist', 'icon')
      switch (loginState) {
        case 'notSignedIn':
          if (confirm(t('please_login_to_add'))) {
            void push('/signup')
            setPreviewVideoId(undefined)
          }
          break
        case 'noProfile':
          alert(t('please_create_profile_to_add'))
          break
        case 'conflict':
          alert(t('require_another_login'))
          break
        case 'signedIn':
          setUiType('select')
          setPlayListVideoInfo({ videoId })
          break
      }
    },
    [loginState, push, sendGaInfo, setPlayListVideoInfo, setUiType, t, setPreviewVideoId],
  )

  return {
    onClose,
    onSetup,
    onClickVideoPlay,
    onClickAddPlaylist,
    sendTrailInsightsEvent,
  }
}
